import { type IQueryParamsForecast } from '../interfaces/forecastQueryParams.interface'

export const QueryParamsForecastModel: IQueryParamsForecast = {
  sort: {},
  page: 1,
  perPage: 25,
  query: '',
  spaceOwner: '',
  arrivalAirport: '',
  finalDestination: '',
  transporterId: [],
  flightsEta: ''
}
