import { type IQueryParamsManagement } from '../interfaces/management-filter.interface'

export const QueryParamsManagementModel: IQueryParamsManagement = {
  sort: {},
  page: 1,
  perPage: 25,
  query: '',
  status: '',
  loadingAirport: '',
  finalDestination: '',
  serviceType: '',
  transporterId: [],
  pickupDate: ''
}
