import {
  type IFlightsData,
  type IForecastOrders,
  type IShipmentAirportData
} from '../interfaces/forecast-row.interface'

export const shipmentAirportModel: IShipmentAirportData = {
  arrivalAirport: '',
  arrivalDate: 0,
  assignedPos: 0,
  assignedTrucks: 0,
  awb: 0,
  cbm: 0,
  flights: 0,
  id: '',
  shipmentId: '',
  trucksConfirmed: 0,
  trucksRejected: 0,
  trucksRequested: 0
}

export const flightsDataModel: IFlightsData = {
  id: '',
  assignedPos: 0,
  assignedTrucks: 0,
  awbs: 0,
  mawb: 0,
  cbm: 0,
  cpSummary: '',
  destinationCountry: '',
  eta: 0,
  etd: 0,
  flight: '',
  forecastStatus: '',
  origin: '',
  shipmentAirport: shipmentAirportModel,
  shipmentAirportId: '',
  spaceOwner: '',
  trucksConfirmed: 0,
  trucksRejected: 0,
  trucksRequested: 0,
  userCreated: false,
  arrivalAirport: '',
  remarks: ''
}

export const forecastOrderModel: IForecastOrders = {
  id: '',
  arrivalAirport: '',
  arrivalDate: '',
  assignedPos: 0,
  assignedTrucks: 0,
  mawb: 0,
  cbm: 0,
  flights: [],
  shipmentId: '',
  trucksRequested: 0,
  trucksConfirmed: 0,
  trucksRejected: 0
}
