import { TRUCK_TYPE_MODEL } from '../../Planning/models/planning.models'
import { type IManagementOrder, type ITransporter } from '../interfaces/management-row.interface'

export const TRANSPORTER_MODEL: ITransporter = {
  name: '',
  id: ''
}

export const MANAGEMENT_MODEL: IManagementOrder = {
  id: '',
  status: '',
  transporter: TRANSPORTER_MODEL,
  optimization: '',
  truckPlate: '',
  pickupDate: '',
  handlingAtd: '',
  destinationEta: '',
  destinationAta: '',
  loadingAirport: '',
  loadingWarehouse: '',
  truckDestination: '',
  deliveryLocaton: '',
  flight: '',
  truckCat: '',
  height: '',
  boxes: 0,
  actualCbm: '',
  actualPos: 0,
  actualAwbs: 0,
  totalUlds: '',
  truckAlias: '',
  truckStatus: '',
  cbm: 0,
  uld: 0,
  kg: 0,
  assignedPos: 0,
  trailerPlate: '',
  serviceRequestStatus: 0,
  loadingInstructionsStatus: 0,
  arrivalAdviceStatus: 0,
  truckType: TRUCK_TYPE_MODEL,
  mawb: 0,
  deliveryLocation: '',
  serviceRequestSentFailedReason: '',
  loadingInstructionsSentFailedReason: '',
  arrivalAdviceSentFailedReason: '',
  spaceOwner: '',
  remarks: '',
  flightRemarks: '',
  flightEta: ''
}
