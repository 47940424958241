import { validate as isValidUUID } from 'uuid'

export const checkUserIsLoged = (): void => {
  const id: string = JSON.parse(localStorage.getItem('user-storage') ?? '{}').state.id

  if (!isValidUUID(id)) {
    window.location.replace('/auth')
  }
}

export const checkUserIsNotLoged = (): void => {
  const userState: { id: string; permissions: string[] } = JSON.parse(
    localStorage.getItem('user-storage') ?? '{}'
  ).state

  const navigateTo = (permissions: string[]) => {
    if (permissions.includes('kaira.issue.dashboard')) {
      window.location.replace('/')
    } else if (permissions.includes('kaira.order_delivery')) {
      window.location.replace('/orders')
    } else if (permissions.includes('atlas.loadplanner')) {
      window.location.replace('/atlas/load-planner')
    } else if (permissions.includes('atlas_dmtt.forecast')) {
      window.location.replace('/dmtt/forecast')
    } else {
      window.location.replace('/auth')
    }
  }

  if (isValidUUID(userState.id)) {
    navigateTo(userState.permissions)
  }
}

export const checkUserPermissions = (permissonType: string): void => {
  const permissions = JSON.parse(localStorage.getItem('user-storage') ?? '{}').state.permissions
  permissions.length === 0 && window.location.replace('/auth')
  !permissions.includes(permissonType) && window.location.replace('/auth')
}
