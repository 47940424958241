import { type IQueryParamsPlanning, type IQueryParamsRequestedTrucks } from '../interfaces/planning.interface'

export const QueryParamsPlanningModel: IQueryParamsPlanning = {
  sort: {},
  page: 1,
  perPage: 25,
  query: '',
  arrivalAirport: '',
  brand: '',
  finalDestination: '',
  serviceType: '',
  loadingDate: ''
}

export const QueryParamsRequestedTrucksModel: IQueryParamsRequestedTrucks = {
  sort: {},
  page: 1,
  perPage: 25,
  query: ''
}
