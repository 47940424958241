/* eslint-disable @typescript-eslint/no-misused-promises */
import { useHttp } from '@core/hooks/useHttp'
import { Box } from '@mui/material'
import { KairaTypography } from '@shared/components/ui/typography/typography'
import { forgotPassword } from '@shared/constants/auth-http'
import { useState, type FormEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { EmailSent } from './EmailSent'
import { EnterEmail } from './EnterEmail'

export const ForgotPassword = () => {
  const { t } = useTranslation()
  // const [isLogged, setisLogged] = useState<boolean>(false)
  const [emailSentCorrectly, setEmailSentCorrectly] = useState(false)
  const [isError, setIsError] = useState(false)
  const [email, setEmail] = useState('')
  const { post } = useHttp()

  const handleInputChange = (event: { target: { value: any } }) => {
    setEmail(event.target.value as string)
  }

  // useEffect(() => {
  //   const id = JSON.parse(localStorage.getItem('user-storage') ?? '{}').state.id
  //   if (id && id !== 0) {
  //     setisLogged(true)
  //   }
  // }, [])

  // if (isLogged) window.location.replace('/')

  const handleSend = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValidated = pattern.test(email)
    if (isEmailValidated) {
      setIsError(false)
      const response = await post(forgotPassword, { email })

      if (response.success) {
        setEmailSentCorrectly(true)
      } else {
        setEmailSentCorrectly(false)
      }
    } else {
      setIsError(true)
    }
  }

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: 'unset', lg: 'unset' },
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        background: '#1e1e1e',
        backgroundImage: "url('/images/loginBackground.png')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}>
      <Box
        sx={{
          width: { xs: '90%', sm: 'unset', lg: 'unset' },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
        <Box
          sx={{
            width: { xs: '100%', sm: '540px', lg: '540px' },
            mb: '32px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}>
          <Box
            component='img'
            sx={{
              height: '55px',
              width: { xs: '100%', sm: '240px', lg: '240px' },
              marginBottom: '40px'
            }}
            src='/images/logoNegative.svg'
          />
          <KairaTypography variant={'typoForgotPasswordTitle'}>{t('AUTH.FORGOT_PASSWORD')}</KairaTypography>
        </Box>
        {emailSentCorrectly ? (
          <EmailSent handleSend={handleSend} />
        ) : (
          <EnterEmail handleInputChange={handleInputChange} handleSend={handleSend} isError={isError} email={email} />
        )}
      </Box>
    </Box>
    // <>
    //   {!isLogged && (
    //   )}
    // </>
  )
}
