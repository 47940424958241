import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { type IQueryParamsManagement } from '../interfaces/management-filter.interface'
import { type IManagementOrder } from '../interfaces/management-row.interface'
import { MANAGEMENT_MODEL } from '../models/ManagementOrderModel'
import { QueryParamsManagementModel } from '../models/queryManagement'

interface IManagementStore {
  managementOrder: IManagementOrder
  queryParamsManagement: typeof QueryParamsManagementModel
  openCancelTransportModal: boolean
  selectedTrucks: IManagementOrder[]
  openSendAssignmentModal: boolean
  openSendServiceRequestModal: boolean
  pageSize: number
  managementSearchValue: string
  setManagementOrder: (managementOrder: IManagementOrder) => void
  setQueryParamsManagement: (queryParamsManagement: IQueryParamsManagement) => void
  setOpenCancelTransportModal: (openCancelModal: boolean) => void
  setSelectedTrucks: (selectedTrucks: IManagementOrder[]) => void
  setOpenSendAssignmentModal: (openSendAssignmentModal: boolean) => void
  setOpenSendServiceRequestModal: (openSendServiceRequestModal: boolean) => void
  setPageSize: (pageSize: number) => void
  setManagementSearchValue: (searchValue: string) => void
}

export const managementStore = create(
  persist<IManagementStore>(
    (set) => ({
      managementOrder: MANAGEMENT_MODEL,
      queryParamsManagement: QueryParamsManagementModel,
      openCancelTransportModal: false,
      selectedTrucks: [],
      openSendAssignmentModal: false,
      openSendServiceRequestModal: false,
      pageSize: 10,
      managementSearchValue: '',
      setManagementOrder: (managementOrder: IManagementOrder) => {
        set({ managementOrder })
      },
      setQueryParamsManagement: (queryParamsManagement: IQueryParamsManagement) => {
        set({
          queryParamsManagement
        })
      },
      setOpenCancelTransportModal: (openCancelModal: boolean) => {
        set({ openCancelTransportModal: openCancelModal })
      },
      setSelectedTrucks: (selectedTrucks: IManagementOrder[]) => {
        set({ selectedTrucks })
      },
      setOpenSendAssignmentModal: (openSendAssignmentModal: boolean) => {
        set({ openSendAssignmentModal })
      },
      setOpenSendServiceRequestModal: (openSendServiceRequestModal: boolean) => {
        set({ openSendServiceRequestModal })
      },
      setPageSize: (pageSize: number) => {
        set({ pageSize })
      },
      setManagementSearchValue: (managementSearchValue: string) => {
        set({ managementSearchValue })
      }
    }),
    {
      name: 'managementStore',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
