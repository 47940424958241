/* eslint-disable @typescript-eslint/no-unsafe-argument */
import Http from '../../config/axios'

class I18nService {
  private static instance: I18nService

  public static getInstance(): I18nService {
    if (!I18nService.instance) {
      I18nService.instance = new I18nService()
    }
    return I18nService.instance
  }

  private async getUserConfig(language: string) {
    const urlParams = new URLSearchParams(window.location.search)
    const clientToken = urlParams.get('_t')

    let response
    if (clientToken) {
      response = await Http.get(`api/clients/shared?languageCode=${language}&_t=${clientToken}`)
    } else {
      response = await Http.get(`api/clients?languageCode=${language}`)
    }

    const clientId = JSON.parse(localStorage.getItem('user-storage')!)
    const requestConfiguration = response['request-configuration']

    localStorage.setItem(
      `client-logo-${clientId.state.clientId}`,
      JSON.stringify({
        largeImageUrl:
          requestConfiguration?.largeImageUrl.length > 0
            ? requestConfiguration.largeImageUrl
            : '/images/WhiteKairaLogo.svg',
        smallImageUrl:
          requestConfiguration?.smallImageUrl.length > 0
            ? requestConfiguration.smallImageUrl
            : '/images/WhiteKairaK.svg'
      })
    )

    const remoteTranslations = response['request-translations']
    const localTranslations = await this.getLocalLoginTranslations(language)
    const newTranslations = { ...localTranslations, ...remoteTranslations }
    const filters = response['request-filters']
    localStorage.setItem(`client-columns-${clientId.state.clientId}`, JSON.stringify(response['request-fields']))
    const filtersToArr = Object.keys(filters).map((key) => ({
      name: key,
      data: filters[key]
    }))
    localStorage.setItem(`client-filters-${clientId.state.clientId}`, JSON.stringify(filtersToArr))
    return newTranslations
  }

  public async getTranslationResources(language: string) {
    return { translation: await this.getUserConfig(language) }
  }

  private async getLocalLoginTranslations(language: string) {
    const response = await fetch(`/locales/${language}/translation.json`)
    const data = await response.json()
    return data
  }

  public async getLoginTranslationResources(language: string) {
    return { translation: await this.getLocalLoginTranslations(language) }
  }
}

export default I18nService.getInstance()
