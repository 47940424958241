import { type IPlanningOrder, type ITruckType } from '../interfaces/planning.interface'

export const PLANNING_ORDER: IPlanningOrder = {
  arrivalAirport: '',
  arrivalAirportEta: 0,
  boxes: 0,
  cbm: 0,
  clientId: '',
  cp: '',
  deliveryPlace: '',
  deliveryOrder: 0,
  destinationEta: '',
  flight: '',
  flightId: '',
  handling: '',
  hawb: '',
  id: '',
  invoicingType: '',
  kg: 0,
  loadingEta: 0,
  loadingDate: '',
  loadingInstructions: '',
  loadingOrder: 0,
  mawb: '',
  mawbRep: 0,
  order: '',
  orderDeliveryId: '',
  orderRep: 0,
  originAirport: '',
  originAirportEtd: 0,
  serviceType: '',
  shipmentId: '',
  spaceOwner: '',
  status: '',
  truckRequestId: '',
  uld: '',
  uldRep: 0,
  units: 0
}

export const TRUCK_TYPE_MODEL: ITruckType = {
  truckCat: '',
  height: '',
  id: '',
  heightMeters: 0,
  lengthMeters: 0,
  maxCbm: 0,
  widthMeters: 0
}
