import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import {
  type IPlanningOrder,
  type IQueryParamsPlanning,
  type IQueryParamsRequestedTrucks,
  type IRequestedTruck
} from '../interfaces/planning.interface'
import { PLANNING_ORDER } from '../models/planning.models'
import { QueryParamsPlanningModel, type QueryParamsRequestedTrucksModel } from '../models/queryPlanning'

interface IPlanningStore {
  planningOrder: IPlanningOrder
  filteredPlanningOrders: IPlanningOrder[]
  selectedPlanningOrders: IPlanningOrder[]
  queryParamsRequestedTrucks: typeof QueryParamsRequestedTrucksModel
  openPlanningModal: boolean
  queryParamsPlanning: typeof QueryParamsPlanningModel
  pageSize: number
  orderListSearchValue: string
  requestedTrucksSearchValue: string
  setPlanningOrder: (planningOrder: IPlanningOrder) => void
  setFilteredPlanningOrders: (planningOrders: IPlanningOrder[]) => void
  setQueryParamsRequestedTrucks: (queryParamsRequestedTrucks: IQueryParamsRequestedTrucks) => void
  setSelectedPlanningOrders: (row: IPlanningOrder[]) => void
  setOpenPlanningModal: (openModal: boolean) => void
  setQueryParamsPlanning: (queryParamsPlanning: IQueryParamsPlanning) => void
  requestedTrucks: IRequestedTruck[]
  pageRequestedTrucks: number
  pageSizeRequestedTrucks: number
  setRequestedTrucks: (dataRequestedTrucks: IRequestedTruck[]) => void
  setPageRequestedTrucks: (pageRequestedTrucks: number) => void
  paginatedRequestedTrucks: (state: any) => IRequestedTruck[]
  totalPagesRequestedTrucks: (state: any) => number
  setPageSize: (pageSize: number) => void
  setOrderListSearchValue: (value: string) => void
  setRequestedTrucksSearchValue: (value: string) => void
}

export const planningStore = create(
  persist<IPlanningStore>(
    (set) => ({
      planningOrder: PLANNING_ORDER,
      filteredPlanningOrders: [],
      selectedPlanningOrders: [],
      queryParamsRequestedTrucks: QueryParamsPlanningModel,
      allPlanningOrders: [],
      openPlanningModal: false,
      queryParamsPlanning: QueryParamsPlanningModel,
      pageSize: 25,
      orderListSearchValue: '',
      requestedTrucksSearchValue: '',
      setPlanningOrder: (planningOrder: IPlanningOrder) => {
        set({ planningOrder })
      },
      setFilteredPlanningOrders: (filteredPlanningOrders: IPlanningOrder[]) => {
        set({ filteredPlanningOrders })
      },
      setQueryParamsRequestedTrucks: (queryParamsRequestedTrucks: IQueryParamsRequestedTrucks) => {
        set({ queryParamsRequestedTrucks })
      },
      setSelectedPlanningOrders: (selectedPlanningOrder: IPlanningOrder[]) => {
        set({ selectedPlanningOrders: selectedPlanningOrder })
      },
      setOpenPlanningModal: (openModal: boolean) => {
        set({ openPlanningModal: openModal })
      },
      setQueryParamsPlanning: (queryParamsPlanning: IQueryParamsPlanning) => {
        set({ queryParamsPlanning })
      },
      setPageSize: (pageSize: number) => {
        set({ pageSize })
      },
      requestedTrucks: [],
      pageRequestedTrucks: 1,
      pageSizeRequestedTrucks: 10,
      setRequestedTrucks: (dataRequestedTrucks: IRequestedTruck[]) => {
        set({ requestedTrucks: dataRequestedTrucks })
      },
      setPageRequestedTrucks: (pageRequestedTrucks: number) => {
        set({ pageRequestedTrucks })
      },
      paginatedRequestedTrucks: (state: any) => {
        const startIndex = (state.pageRequestedTrucks - 1) * state.pageSizeRequestedTrucks
        const endIndex = startIndex + state.pageSizeRequestedTrucks
        return state?.requestedTrucks?.slice(startIndex, endIndex)
      },
      totalPagesRequestedTrucks: (state: any) =>
        Math.ceil(state?.requestedTrucks?.length / state?.pageSizeRequestedTrucks),
      setOrderListSearchValue: (value: string) => {
        set({ orderListSearchValue: value })
      },
      setRequestedTrucksSearchValue: (value: string) => {
        set({ requestedTrucksSearchValue: value })
      }
    }),
    {
      name: 'planningStore',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
