import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { type IFlightsData, type IForecastOrders } from '../interfaces/forecast-row.interface'
import { type IQueryParamsForecast } from '../interfaces/forecastQueryParams.interface'
import { flightsDataModel, forecastOrderModel } from '../models/forecastOrderModel'
import { QueryParamsForecastModel } from '../models/queryForecastModel'

interface IForecastState {
  flightData: IFlightsData
  forecastOrders: IForecastOrders[]
  selectedFlights: IFlightsData[]
  queryParamsForecast: typeof QueryParamsForecastModel
  pageSize: number
  forecastSearchValue: string
  setFlightData: (flightData: IFlightsData) => void
  setForecastOrders: (forecastOrders: IForecastOrders[]) => void
  setSelectedFlights: (selectedFlights: IFlightsData[]) => void
  setQueryParamsForecast: (queryParamsForecast: IQueryParamsForecast) => void
  setPageSize: (pageSize: number) => void
  setForecastSearchValue: (forecastSearchValue: string) => void
}

export const forecastStore = create(
  persist<IForecastState>(
    (set) => ({
      flightData: flightsDataModel,
      forecastOrders: [forecastOrderModel],
      selectedFlights: [],
      queryParamsForecast: QueryParamsForecastModel,
      pageSize: 10,
      forecastSearchValue: '',
      setFlightData: (flightData: IFlightsData) => {
        set({ flightData })
      },
      setForecastOrders: (forecastOrders: IForecastOrders[]) => {
        set({ forecastOrders })
      },
      setSelectedFlights: (selectedFlights: IFlightsData[]) => {
        set({ selectedFlights })
      },
      setQueryParamsForecast: (queryParamsForecast: IQueryParamsForecast) => {
        set({ queryParamsForecast })
      },
      setPageSize: (pageSize: number) => {
        set({ pageSize })
      },
      setForecastSearchValue: (forecastSearchValue: string) => {
        set({ forecastSearchValue })
      }
    }),
    {
      name: 'forecastStore',
      storage: createJSONStorage(() => localStorage)
    }
  )
)
